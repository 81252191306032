/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import * as PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/SEO/SEO'
import IOS from '../img/w_apple-dwn.png'
import Android from '../img/w_android-dwn.png'
import Content, { HTMLContent } from '../components/Content'
import { isIOS, isAndroid, BrowserView } from 'react-device-detect'

const AppPageTemplate = ({ content, contentComponent }) => {
    const buttonRender = () => {
        if (isIOS) {
            return (
                <div className="columns application_button is-5">
                    <a href="https://apps.apple.com/us/app/georgian-wine-finder/id1439021125">
                        <img src={IOS} alt="IOS Application" />
                    </a>
                </div>
            )
        }
        if (isAndroid) {
            return (
                <div className="columns application_button is-5">
                    <a href="https://play.google.com/store/apps/details?id=com.wine.finder">
                        <img src={Android} alt="Android Application" />
                    </a>
                </div>
            )
        }
        return (
            <div className="columns application_button is-5">
                <a
                    className="column is-6 margin_left"
                    href="https://play.google.com/store/apps/details?id=com.wine.finder"
                >
                    <img src={Android} alt="Android Application" />
                </a>
                <a
                    className="column is-6"
                    href="https://apps.apple.com/us/app/georgian-wine-finder/id1439021125"
                >
                    <img src={IOS} alt="IOS Application" />
                </a>
            </div>
        )
    }
    const PageContent = contentComponent || Content
    return (
        <section className="hero is-medium">
            <div className="hero-body">
                <div className="container">
                    <div className="column is-8 is-offset-2">
                        <PageContent className="content" content={content} />
                        {buttonRender()}
                    </div>
                </div>
            </div>
        </section>
    )
}

AppPageTemplate.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string,
    contentComponent: PropTypes.func,
    tags: PropTypes.array,
    langKey: PropTypes.string,
}

class AppPage extends React.Component {
    render() {
        var dataMarkdown = []
        if (this.props.data !== null) {
            dataMarkdown = this.props.data.markdownRemark
        }
        const jsonData = this.props.data.allArticlesJson.edges[0].node.articles
        const { frontmatter } = dataMarkdown
        const langKey = frontmatter.lang
        const tags = frontmatter.tags
        return (
            <Layout
                className="container"
                data={this.props.data}
                jsonData={jsonData}
                location={this.props.location}
            >
                <SEO frontmatter={frontmatter} />
                <div>
                    <AppPageTemplate
                        contentComponent={HTMLContent}
                        title={dataMarkdown.frontmatter.title}
                        content={dataMarkdown.html}
                        tags={tags}
                        langKey={langKey}
                    />
                </div>
            </Layout>
        )
    }
}

AppPage.propTypes = {
    data: PropTypes.object.isRequired,
    location: PropTypes.object,
}

export default AppPage

export const pageQuery = graphql`
    query AppPageQuery($id: String!) {
        site {
            siteMetadata {
                languages {
                    defaultLangKey
                    langs
                }
            }
        }
        allArticlesJson(filter: { title: { eq: "home" } }) {
            edges {
                node {
                    articles {
                        en
                        ka
                    }
                }
            }
        }
        markdownRemark(id: { eq: $id }) {
            html
            frontmatter {
                id
                title
                description
                tags
                lang
            }
            fields {
                slug
            }
        }
    }
`
